import React from 'react'
import googleIcon from '../../../public/images/svg/google.svg'
import microsoftIcon from '../../../public/images/svg/microsoft.svg'
import linkedinIcon from '../../../public/images/svg/linkedin.svg'
import { Button } from 'primereact/button'

export default function OAuthButton({ media, label, setLoggedIn }) {

    const icons = {
        Google: googleIcon,
        Azure: microsoftIcon,
        LinkedIn: linkedinIcon,
    }

    const features = 'title=Login,width=500,height=600,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=300,top=200'

    const src = icons[media]

    function initLogin() {
        const url = '/auth/' + media.toLowerCase()
        // if (isMobile()) {
        window.location.href = url
        // } else {
        //     const signinWin = window.open(url, 'SignIn', features)
        //     setExternalPopup(signinWin)
        //     CheckLoginStatus()
        // }
    }


    // @ts-ignore
    return (
        <div className='mb-3 flex w-full min-w-full justify-content-center sm:w-7'>
            <Button
                onClick={() => initLogin()}
                className='bg-white text-gray-600 border-bluegray-200 w-16rem hover:bg-gray-200'>
                <img
                    src={src}
                    height={32}
                    width={32}
                    alt={label}
                    className='mr-3'
                /><span>{/*<FormattedMessage
                id={'general1.log in with'}
                defaultMessage={'Log in with'} />*/}Log in with {label}</span>
            </Button>
        </div>
    )
}
